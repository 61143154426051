$defaultIconPrimary: #3699ff;
$defaultFaEdit: #3699ff;
$defaultFaClone: #3699ff;
$defaultFaDis: #b5b5c3;
$defaultbrancdColor: #1a1a27;


:root {
  --ck-color-image-caption-background: hsl(0, 0%, 97%);
  --ck-color-image-caption-text: hsl(0, 0%, 20%);
  --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
  --ck-color-mention-text: hsl(341, 100%, 30%);
  --ck-color-table-caption-background: hsl(0, 0%, 97%);
  --ck-color-table-caption-text: hsl(0, 0%, 20%);
  --ck-highlight-marker-blue: hsl(201, 97%, 72%);
  --ck-highlight-marker-green: hsl(120, 93%, 68%);
  --ck-highlight-marker-pink: hsl(345, 96%, 73%);
  --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
  --ck-highlight-pen-green: hsl(112, 100%, 27%);
  --ck-highlight-pen-red: hsl(0, 85%, 49%);
  --ck-image-style-spacing: 1.5em;
  --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
  --ck-todo-list-checkmark-size: 16px;
}

.marker-yellow {
  background-color: var(--ck-highlight-marker-yellow);
}

/* ckeditor5-highlight/theme/highlight.css */
.marker-green {
  background-color: var(--ck-highlight-marker-green);
}

/* ckeditor5-highlight/theme/highlight.css */
.marker-pink {
  background-color: var(--ck-highlight-marker-pink);
}

/* ckeditor5-highlight/theme/highlight.css */
.marker-blue {
  background-color: var(--ck-highlight-marker-blue);
}

/* ckeditor5-highlight/theme/highlight.css */
.pen-red {
  color: var(--ck-highlight-pen-red);
  background-color: transparent;
}

/* ckeditor5-highlight/theme/highlight.css */
.pen-green {
  color: var(--ck-highlight-pen-green);
  background-color: transparent;
}

.primary-color{
  background-color: var(--default-icon-primary);
}

i.fa.fa-clone {
  color: var(--default-icon-primary, $defaultIconPrimary) !important;
}

.btn.btn-hover-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-hover-primary:focus:not(.btn-text),
.btn.btn-hover-primary.focus:not(.btn-text) {
  color: #FFFFFF !important;
  background-color: var(--default-icon-primary, $defaultIconPrimary) !important;
  border-color: var(--default-icon-primary, $defaultIconPrimary) !important;
}

.btn.btn-hover-primary:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-hover-primary:not(:disabled):not(.disabled).active,
.show>.btn.btn-hover-primary.dropdown-toggle,
.show .btn.btn-hover-primary.btn-dropdown {
  color: #FFFFFF !important;
  background-color: var(--default-icon-primary, $defaultIconPrimary) !important;
  border-color: var(--default-icon-primary, $defaultIconPrimary) !important;
}

.btn.btn-primary {
  color: #FFFFFF;
  background-color: var(--default-icon-primary, $defaultIconPrimary) !important;
  border-color: var(--default-icon-primary, $defaultIconPrimary) !important;
}

.brand-dark .brand {
  background-color: var(--default-brand, $defaultbrancdColor) !important;
  box-shadow: none;
}

.nav .show>.nav-link,
.nav .nav-link:hover:not(.disabled),
.nav .nav-link.active {
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  color: var(--default-icon-primary, $defaultIconPrimary) !important; // need to higlight
}

.header-fixed.subheader-fixed.subheader-enabled .wrapper {
  padding-top: 40px !important;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  padding: 0 !important;
  max-width: 800px;
}

.bg-secondary {
  background-color: #f7f7f7 !important;
}

.content {
  padding: 0 !important;
}

.addButton {
  position: absolute;
  right: 50px;
  top: 25px;
}

.card.card-custom {
  box-shadow: 0px 0px 0px 0px !important;
  height: 100%;
}

// Onboarding signup information request radio
.cm-inforeqradio {
  padding: 10px;
  border: 1px solid #80808030;
  border-radius: 2px;

  .custom-control-input {
    width: 100%;
  }

  .custom-control-label::before {
    top: 0;
    left: 25.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }

  .custom-control-label::after {
    top: 0;
    left: 25.5rem;
    width: 0;
    height: 0;
  }
}

.title-secondary {
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  color: #797979;
  padding: 0;
  margin: 0;
}

.modal-text-secondary {
  font-size: 13px;
  font-weight: 500;
  line-height: 19.6px;
  color: #797979;
}

.text-header {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: #3F4254;
}

.cm-signup-info-list,
.cm-onboard-steps-list,
.cm-tc-step-detail-list {
  padding: 0 10px;
  border: 1px solid #80808030;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// Onboarding signup information request radio

//Subscription
.cm-subscription-plan-list {
  padding: 0 10px;
  border: 1px solid #80808030;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

//Subscription

// Set ck editor hight
.ck .ck-content {
  height: 160px;
}

// Modal close button css (x)
.modal .modal-header .close span {
  display: block;
}

.react-datepicker-wrapper {
  width: 100%;
}

// set Area Of Operation tab table design (Serivce Provider)
.cm-header {
  text-transform: uppercase;
  color: #4a89dc;
}

.cm-area-of-operatiion .cm-empty-msg {
  color: #626262;
}

// delete button design
.cm-btn-delete {
  width: 45px;
}

.cm-border-radius {
  border-radius: 12px;
}

//Switch Account
.cm-switch-account {
  cursor: pointer;

  .cm-link {
    color: white;
  }
}

.cm-color-goldenrod {
  color: goldenrod;
}

.cm-table-width {
  width: 100%;
}

// set opacity to start tabindex in image upload button
.cm-input-upload-h {
  max-height: 0;
  max-width: 0;
  opacity: 0;
}

// Set design for tabs
.nav.nav-tabs .nav-item {
  padding: 9px 12px;
}

// set Service Provider additional business dd message design
.cm-business-multiSelect .item-renderer span {
  width: 100%;
}

.cm-table-striped tbody tr:nth-of-type(odd) {
  background-color: #F9F9F9;
}

.request-payout-btn button {
  font-family: Inter;
  font-weight: 600;
  border-radius: 8px;
}

.pd-5 {
  padding: 5px !important;
}

.MuiAppBar-colorPrimary {
  background-color: #FFFFFF !important;
}

.MuiPaper-elevation4 {
  border-bottom: 2px solid #E8E8E8 !important;
  box-shadow: none !important;
}

.MuiTab-wrapper {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  text-transform: none;
}

.font-inter button{
  font-family: Inter;
}

.MuiTypography-root {
  .form-control {
    background-color: #F7F7F7 !important;
  }
}

.card-container {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card-item {
  flex-basis: calc(25% - 5px);
}

.MuiBox-root {
  padding: 0px !important;
}

.filter-container {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
}

.filter-search {
  flex-basis: calc(60% - 10px);
}

.filter-date {
  flex-basis: 20%;
}

.cm-btn-sm-w-i {
  padding: 3px 6px;
}

.cm-btn-sm-w-i>i {
  font-size: 12px !important;
}

//To stick cancel and save button at bottom
.cm-form-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 30px;
}

// note added by ui
.cm-note-added-by {
  font-size: 12px;
  font-style: italic;
  color: gray;
}

/* Additional business drop-down */
.cm-business-multiSelect .select-panel .options {
  max-height: 230px !important;
}

/* Additional business drop-down */

/*Page content css - start*/
.cm-page-content {
  padding-bottom: 20px !important;
}

/*Page content css - end*/

/* Form Button Fixed to Bottom */
.cm-fixed-btn-wrapper {
  position: fixed;
  bottom: 44px;
  right: 0;
  background: #fff;
  // z-index: 10;
  margin-bottom: -3.25rem !important;
  padding: 10px 1rem;
}

/* Footer - Start */
.cm-scrolltop {
  position: unset !important;
  right: unset !important;
  bottom: unset !important;
  height: 25px !important;
  width: 25px !important;
}

.cm-scrolltop>i {
  font-size: 14px;
}

.cm-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - 265px);
  padding: 8px 0;
  margin: 0;
  border-top: 1px solid #ebedf3;
  min-height: 44px;
  justify-content: center;
  z-index: 10;
}

.cm-text {
  font-size: 14px !important;
}

.aside-minimize .cm-footer {
  width: calc(100% - 70px);
}

/* Footer - End */

/* Custom Scrollbar - Start */
::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #F6F4F4;
}

::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}

::-webkit-scrollbar-thumb:hover {
  background: #777777;
}

/* Custom Scrollbar - End */

.card.card-custom>.card-body {
  padding: 1rem !important;
}

.card.card-custom>.cm-pb-on-fix-btn {
  padding-bottom: 1rem !important;
}

.aside-minimize:not(.aside-minimize-hover) .aside .cm-switch-account {
  display: none;
}

.member-popup-scroll {
  max-height: 280px;
  overflow-y: auto;
  overflow-x: hidden;
}

.cm-header-content-name-wrapper {
  max-width: 30ch;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

::placeholder {
  font-size: 12px;
}

.cm-checkbox-big {
  transform: scale(1.5);
}

.cm-action-icons i {
  font-size: 1rem;
}

.cm-action-icons {
  .fa-trash {
    color: red;
  }

  .fa-edit {
    color: var(--default-fa-edit, $defaultFaEdit);
  }

  d .fa-clone {
    color: var(--default-fa-clone, $defaultFaClone);
  }

  .dis {
    color: var(--default-fa-dis, $defaultFaDis);
  }
}

.cm-disabled {
  .dropdown-container {
    background-color: #f3f6f9 !important;
  }
}

.cm-dropdown-position-top .dropdown-content {
  top: auto !important;
  bottom: 100%;
}

.cm-table-fixed-grid0 {
  height: calc(100vh - 265px);
}

.cm-table-fixed-grid1 {
  height: calc(100vh - 280px);
}

.cm-table-fixed-grid2 {
  height: calc(100vh - 322px);
}

.notification-exit {
  visibility: visible;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.notification-exit.notification-exit-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}

.cm-dragndrop-wrapper {
  height: calc(100vh - 242px);
  overflow-y: auto;
  padding: 12.5px;
}

.cm-btn-sm-x {
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.42rem;
  max-width: 10.33333%;
}

.cm-icon-primary {
  color: var(--default-icon-primary, $defaultIconPrimary) !important; //#3699ff;
}

.custom-radio .custom-control-input:checked~.custom-control-label.success::before {
  background-color: #93c01f;
  border-color: #93c01f;
}

.custom-radio,
.custom-checkbox {
  .custom-control-input {
    &:checked {
      ~ {
        .custom-control-label.success {
          &::before {
            background-color: #93c01f;
            border-color: #93c01f;
          }
        }

        .custom-control-label.error {
          &::before {
            background-color: #d0021b;
            border-color: #d0021b;
          }
        }
      }
    }
  }
}

.ck-editor__editable_inline {
  min-height: 150px !important;
}

.emailTemplateEditor .ck-editor__editable_inline {
  min-height: 430px !important;
}

.ck-content .table {
  width: 100% !important;
}

.ck-content .table .p {
  margin: 0 !important;
}

.cm-bg-error {
  background-color: #d0021b;
}

.cm-bg-success {
  background-color: #93c01f;
}

.aside-minimize .cm-logo {
  display: none;
}

.aside-minimize.aside-minimize-hover .cm-logo {
  display: initial;
}

.cm-training-wrapper {
  height: calc(100vh - 126px);
  overflow-y: auto;
}

.popup-view {
  background-color: var(--default-aside-theme, $defaultbrancdColor) !important;
}

.ck-content i {
  font-size: 1em;
  color: inherit;
}

.cm-training-wrapper i {
  font-size: 1em;
  color: inherit;
}

.cm-result-page {
  overflow-y: auto;
  max-height: calc(100vh - 188px);
  padding: 0 15px 15px;
}

.cm-training-wrapper .media iframe {
  width: calc(100vw - 30px) !important;
  min-height: 55vw;
}

.ck-media__wrapper div {
  padding-bottom: 20% !important;
}

.emailTemplateFooter {
  flex-direction: row-reverse;
}

.btn.btn-emailtemp {
  background-color: rgb(43, 81, 92) !important;
  color: #fff !important;
  border-color: rgb(43, 81, 92) !important;
}

.leaderboard {
  box-shadow: 0 0 40px -10px rgba(0, 0, 0, .4);
}

.template-tag-list {
  max-height: 470px;
  overflow-y: scroll;
  border: 2px solid grey;
  border-radius: 0px;
}

.clubReadyIcon {
  height: 25px !important;
  width: 25px !important;
}

.inputText-label-Icon {
  height: 20px !important;
  width: 20px !important;
  margin-left: 4px;
}

.label.label-status {
  height: 50px;
}

.brand-dark .brand .cm-logo+.btn .svg-icon svg g [fill],
.brand-dark .brand .cm-logo+.btn.active .svg-icon svg g [fill] {
  fill: #ffffff;
}

.label-light-brown {
  color: #523526;
  background-color: #dfb29c;
}

.green-checkbox {
  background-color: #198754;
  appearance: none;
  height: 13px;
  width: 13px;
}

.yellow-checkbox {
  background-color: #ffc107;
  appearance: none;
  height: 13px;
  width: 13px;
}

.green-label, .yellow-label {
  position: relative !important;
}

.green-label::after, .yellow-label::after {
  content: "\f00c";
  position: absolute;
  top: 0;
  left: 0;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #fff;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/*user profile start*/
.cm-user-profile {
  .dropdown-toggle {
    border-radius: 80px;
    background-color: transparent !important;
    color: #3F4254 !important;
    border-color: #3F4254 !important;
    font-size: 14px;

    &::after, &:hover {
      color: #3F4254 !important;
    }
  }
}

.cm-user-profile2 {
  .dropdown-toggle {
    border-radius: 80px;
    background-color: transparent !important;
    color: #3F4254 !important;
    border-color: #3F4254 !important;
    font-size: 14px;

    &::after, &:hover {
      color: #3F4254 !important;
    }
  }
}
/*user profile end*/
.cm-consultations {
    @media screen and (max-width: 386px) {
        padding-right: 27px;
    }

    @media screen and (max-width: 290px) {
        padding-right: 0;
    }
}

.cm-disabled-button {
  background-color: gray;
  color: white; 
}

.table-container {
  text-align: center;
}

.data-table {
  width: 100%;  
  border-collapse: collapse;
  border: 1px solid #ccc;
}

.data-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  border-bottom: none; /* Remove the border-bottom in the header */
}

.data-table th, .data-table td {
  padding: 10px;
  border-right: 1px solid #ccc; /* Add vertical lines between label and value columns */
}

.data-table td:last-child {
  border-right: none; /* Remove the right border on the last cell in each row */
}

.data-table td {
  text-align: left;
}

.input-with-underline {
  position: relative;
}
/* CSS for the input with underline effect */
.input-with-underline {
  display: flex;
  flex-direction: column;
  position: relative;
}

.input-with-underline input {
  border: none;
  outline: none;
  padding: 5px;
}

.input-underline {
  width: 100%;
  height: 2px;
  background-color: #000;
}

.react-html-parcer a {
  word-break: break-all;
}


@media (max-width: 576px) {
  .cm-user-profile {
    .dropdown-toggle {
      font-size: 10px !important; 
    }
  }
  .cm-user-profile2 {
    .dropdown-toggle {
      padding: 4px 8px !important;
      width: fit-content !important;
    }
  }
}

@media (max-width: 415px) {
  .cm-user-profile {
    .dropdown-toggle {
      font-size: 8px !important; 
    }
  }
  .cm-user-profile2 {
    .dropdown-toggle {
      font-size: 10px !important;
    }
  }
}

@media (max-width: 768px) {
  .card-item {
    flex-basis: calc(50% - 5px);
  }
  .filter-search {
    flex-basis: 100%;
  }
  .filter-date {
    flex-basis: calc(50% - 5px);
  }
}

.card.card-custom > .card-body {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.action-items-container{
  display:flex;
  justify-content:center;
  align-items:center;
  width: 100vw;
  transition: transform 2s ease;
}

.action-items-container-first{
        position: fixed;
        bottom: 40px;
        background-color: white;
        box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
        padding: 5px;
        width: 80%;
        z-index: 2 ;
        border-radius:10px;
        
}

.action-items-container-second{
  bottom: 130px;
  position:fixed;
  background-color: white;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  padding: 5px;
  z-index: 1;
  border-radius:10px;
  width:75%;
  height: 80px;
  transition: transform 0.8s ease;
}

.action-items-button{
  bottom: 187px;
  position: fixed;
  background-color: white;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); 
  padding: 5px;
  z-index: 3;
  border-radius:20px;
  width:100px;
  display: flex;
  justify-content:center;
  align-items:center;
  font-weight: bold;
  color: #25515D;
}

.bottom-sheet-subtitle{
  color: #717171;
  font-weight:500;
  font-size: 14px;
  margin-top: 10px;
}

.business-container {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-top: 30px;
  padding-bottom: 14px;
}

.business-profile-pic {
  width: 80px;
  height: 60px;
  border-radius: 8px;
  object-fit: cover;
}

.business-name {
  font-size: 18px;
  font-weight: 700;
  color: #252527;
}

.view-details {
  font-size: 14px;
  font-weight: 500;
  color: #252527;
  cursor: pointer;
  text-decoration: underline;
  display: block;
  margin-top: 10px;
}

.list-container {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 14px;
  padding-top: 0;
  overflow-y: auto;
}

.description {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #717171;
  padding-top: 0;
  margin-bottom: 20px;
}

.heading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 16px;
}

.custom-heading {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  width: 420px;
  @media (max-width: 768px) {
    width: 320px;
    font-size: 32px;
  }
}

.discount-custom-paragraph {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  margin-top: 8px;
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
  }
}

.party-popper-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.affiliate-discount{
  font-weight: 900;
}
